<template>
  <div class="page-content d-flex align-items-center justify-content-center">
  </div>
</template>

<script>
import authAPI from '../../../api/auth';

export default {
  name: 'Verify',
  metaInfo: {
    title: 'Verify',
  },
  mounted() {
    this.activate();
  },
  methods: {
    async activate() {
      const loader = this.$loading.show();
      await authAPI.activate(this.$route.params.token)
        .then(async (res) => {
          if (!res.error) {
            await this.$store.dispatch('ui/alertSuccess', 'Account verified. Please login to access your account!');
          } else {
            await this.$store.dispatch('ui/alertError', res.error.message);
          }
          this.$router.push('/auth/login');
        });
      loader.hide();
    },
  },
};
</script>
